.blogs {

    $alt-color: #186ec6;

    .hero {
        background-color: #2d84c6;
        background-image: url('../images/blogs/banner.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: grid;
        align-items: center;
        height: 550px;

        .hero__content {
            padding: 30px 0 20px;
            text-align: center;
            margin-bottom: 40px;

            .text {
                max-width: 540px;
                margin: 0 auto;

                @include mq-md {
                    max-width: 720px;
                }

                h1 {
                    color: white;
                    padding: 0 20px;
                    font-size: ($font-size * 2) + 10px;
                    margin-bottom: 0;
                    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);

                    @include mq-lg {
                        font-size: ($font-size * 3);
                    }

                    @include mq-xl {
                        font-size: ($font-size * 3) + 2px;
                    }
                }
            }

            @include mq-sm {
                margin-bottom: 0;
            }

            @include mq-md {
                padding: 50px 0 40px;
            }

            @include mq-lg {
                text-align: left;

                .text {

                    h1,
                    p {
                        padding: 10px 0;
                    }
                }
            }
        }

        .blog-graphics {
            height: 440px;
            width: 440px;
            position: relative;
            float: right;

            >div {
                border: 1px solid rgba(255, 255, 255, 0.7);
                padding: 15px;
                color: #fff;

                h2 {
                    margin: 0;
                    padding: 0 0 10px;
                    font-size: 28px;
                    font-weight: 400;
                    line-height: 26px;
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 15px;
                    overflow: hidden;
                    line-height: 20px;
                    max-height: 40px;
                    font-weight: 300;
                }
            }

            .box-1 {
                position: absolute;
                height: 240px;
                width: 240px;
                right: 0;
                top: 0;

                &::before {
                    content: '';
                    position: absolute;
                    left: -51px;
                    top: -1px;
                    height: 50px;
                    width: 50px;
                    background: url('../images/blogs/hero-quote.png') no-repeat;
                    background-size: contain;
                }
            }

            .box-2 {
                position: absolute;
                height: 240px;
                width: 240px;
                bottom: 0;
                left: 0;

                &::before {
                    content: '';
                    position: absolute;
                    left: -51px;
                    top: -1px;
                    height: 50px;
                    width: 50px;
                    background: url('../images/blogs/hero-quote.png') no-repeat;
                    background-size: contain;
                }
            }

            .box-3 {
                position: absolute;
                height: 150px;
                width: 150px;
                bottom: 0;
                right: 0;
                background-image: url('../images/blogs/write-blog.png');
                background-repeat: no-repeat;
                background-position-y: 60%;
                background-position-x: 55%;
                background-size: 55%;
            }
        }

        &.blogpost {
            background-image: url('../images/blogs/banner-2.png');
        }
    }

    .recent-blogs {
        background-color: #ecf3fb;
        min-height: 100vh;
        padding: 60px 0 60px;
        margin: 0;

        .main-header {
            margin-bottom: 60px
        }
    }

    .blogs-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .blog {
            height: 300px;
            background: #fff;
            border-bottom: 1px solid #eee;

            .details {
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                .content {
                    padding: 1.75rem;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    color: #444;

                    h2 {
                        font-size: 25px;
                        margin: 0;
                        padding-bottom: 10px;

                        a {
                            color: inherit
                        }
                    }

                    p {
                        font-size: 15px;
                        line-height: 22px;
                        max-height: 120px;
                        overflow: hidden;
                        margin-bottom: 0;
                        text-align: justify;

                        @include mq-md {
                            text-align: left
                        }
                    }
                }

                height: 80%;
            }

            .stats {
                height: 20%;
                padding: 10px 1.75rem;
                border-top: 1px solid #eee;
                height: 20%;
                // display: grid;
                // grid-template-columns: auto auto;

                span {
                    position: relative;
                    display: block;
                    float: left;
                    padding: 6px 15px 0 30px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        height: 18px;
                        width: 20px;
                    }

                    &.dated::before {
                        top: 8px;
                        background: url('../images/blogs/date-icon-dark.png') no-repeat;
                        background-size: contain;
                    }

                    &.comments::before {
                        top: 8px;
                        background: url('../images/blogs/comment-icon-dark.png') no-repeat;
                        background-size: contain;
                    }
                }
            }

            &.alt {
                background: $alt-color;
                border-bottom: 1px solid darken($alt-color, 10%);

                .details {
                    position: relative;

                    .content {
                        color: #fff;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: rgba(#125599, 0.5);
                    }
                }

                .stats {
                    border-top: 1px solid darken($alt-color, 10%);

                    span {
                        color: #fff;

                        &.dated::before {
                            top: 8px;
                            background: url('../images/blogs/date-icon-light.png') no-repeat;
                            background-size: contain;
                        }

                        &.comments::before {
                            top: 8px;
                            background: url('../images/blogs/comment-icon-light.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }

            &.no-bg {
                background-image: none !important
            }
        }

        @media (max-width:767px) {
            grid-template-columns: 1fr;
        }

        @media (min-width:768px) and (max-width:1199px) {
            grid-template-columns: 1fr 1fr;

        }
    }

    .blog-post {

        $head-space: 80px;

        .post-view {
            .post-title {
                min-height: $head-space;
                margin: 0;
                display: grid;
                align-items: center;
                color: #2b2c2c;
                padding: 1rem 0;
                font-size: $font-size * 3;
                line-height: 1.15
            }

            .post-image {
                figcaption {
                    font-style: italic;
                    color: lighten($para-text-color, 2);
                    font-size: 13px;
                    margin: 4px 0 0;
                }
            }

            .post-details {

                h2,
                h3,
                h4 {
                    margin: 30px 0 10px;
                }

                h2 {
                    font-size: $font-size * 2.5;
                }

                h3 {
                    font-size: $font-size * 2;
                }

                h4 {
                    font-size: $font-size * 1.75;
                }

                a {
                    color: $blue;
                    border-bottom: 1px solid transparent;
                    display: inline-block;
                    @include easeOut;
                    padding: 0 0 1px;
                    font-size: inherit;
                    line-height: 1.1;

                    &:hover {
                        border-bottom-color: $blue;
                    }
                }

                p {
                    font-size: $font-size;
                    line-break: 1.2;
                    color: $para-text-color;
                    margin: 15px 0;
                }
            }
        }

        .share-box {
            margin: 20px 0;
            padding: 10px 0;
            display: grid;
            grid-template-columns: 140px 1fr;
            align-items: center;

            h4 {
                margin: 0;
            }

            .links {
                a {
                    display: inline-block;
                    width: 45px;
                    height: 45px;
                    background-color: $blue;
                    border-radius: 50%;
                    text-align: center;
                    color: #fff;
                    font-size: 25px;
                    line-height: 1;
                    padding-top: 10px;
                    margin: 0 5px;
                }
            }
        }

        .reply-box {
            background-color: #dff1ff;

            .header {
                padding: 1.5rem 2rem 1.2rem;
                border-bottom: 1px solid #c0cbd3;
                font-size: $font-size + 4px;
                font-weight: 600;
                text-transform: uppercase;
                color: #262626;
            }

            .content {
                padding: 1.5rem 2rem 1.2rem;

                .input-row {
                    padding: 10px 0;
                }

                input,
                textarea {
                    border: none;
                    background: transparent;
                    width: 100%;
                    margin-top: 10px;
                    border: none;
                    border-bottom: 2px solid rgba(151, 187, 215, .5);
                    padding: 0 0 15px;
                    @include easeOut;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                        border-bottom: 2px solid rgba(151, 187, 215, 1);
                    }
                }

                textarea {
                    margin-top: 0;
                }

                .btn-wrapper {
                    margin: 25px 0 30px;

                    button {
                        border: none;
                        background-color: #2078bc;
                        color: #fff;
                        padding: 1rem 1.5rem;
                        border-radius: 3px;
                    }
                }
            }
        }

        .sidebar {
            padding-top: $head-space;

            .searchbox {
                margin-bottom: 20px;
                position: relative;

                input[type=search] {
                    outline: none;
                    border: 1px solid #eee;
                    height: 50px;
                    padding: 10px 15px;
                    margin: 0;
                    width: calc(100% - 50px);
                }

                button {
                    background: #245ca1 url('../images/search-icon-white.png') no-repeat;
                    background-position: center;
                    background-size: 20px;
                    height: 50px;
                    position: absolute;
                    right: 0;
                    width: 50px;
                    border: none;
                    top: 0;

                    &:focus {
                        outline: none
                    }
                }
            }

            .sec {
                background-color: #d0e7f0;
                border: 1px solid #b4ccd6;
                padding: 1rem;
                border-radius: 2px;

                .sec-header {
                    margin: .5rem 0 0;
                    padding: 0 0 .5rem;
                    color: #262626;
                    font-size: $font-size + 8px;
                    font-weight: 600;
                }

                .post-list {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        display: block;
                        padding: 5px 0;

                        a {
                            color: #262626;
                            text-decoration: none;
                            display: block;
                            padding: 2px 0;
                            font-size: $font-size;
                            font-weight: 400;
                            @include easeOut;

                            &:hover {
                                color: $blue;
                            }
                        }

                        &+li {
                            border-top: 1px solid #adc5cf;
                        }
                    }
                }

                &+.sec {
                    margin-top: 30px;
                }
            }

        }

        @include mq-lg {
            margin: 80px 0;
        }
    }

}