html,
body {
    font-family: $font-family;
}

a,
a:hover {
    text-decoration: none;
}

ul {
    padding: 0;
    list-style-type: none;
}

main {
    overflow-x: hidden
}

.modal-backdrop {
    background: #071937;

    &.show {
        opacity: 0.75;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1180px;
    }
}