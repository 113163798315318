.footer__links {
    color: $footer-link-color;
    font-weight: 300;
    font-size: $font-size;
    line-height: ($font-size * 2) - 4px;
}

footer._pmd {

    background-color: $footer-bg-color;
    min-height: 500px;

    @include bs-md {
        height: auto;
        margin-bottom: 0;
    }

    .footer__top {
        padding: 70px 0 100px;
        display: grid;
        grid-template-columns: 1.5fr 1.5fr 2fr;

        @include bs-md {
            grid-template-columns: 1fr;
            padding-bottom: 40px;
        }

    }

    .footer__bottom {
        padding: 24px 0;
    }

    .footer__col {

        &:last-of-type {
            padding-left: 50px
        }

        h4 {
            margin-bottom: 35px;
            text-transform: uppercase;
            color: $footer-col-header-color;
            font-size: $font-size + 2px;
            line-height: ($font-size * 2) - 4px;
            font-weight: 300;

            @include bs-md {
                margin-bottom: 20px;
            }
        }

        ul {

            &.links,
            &.blogs {
                li {
                    position: relative;
                    padding-left: 15px;

                    &::before {
                        content: '\2022';
                        color: $footer-link-color;
                        position: absolute;
                        left: -2px;
                        top: 4px;
                    }

                    a {
                        @extend .footer__links;
                    }
                }
            }

            &.contacts,
            &.addresses {

                span,
                a,
                address {
                    @extend .footer__links;
                }
            }

            a:hover {
                color: lighten($footer-link-color, 20) !important;
                @include easeOut;
            }

        }

        @include bs-md {
            padding-bottom: 40px;

            &:last-of-type {
                padding-left: 0
            }
        }
    }

    .copyright {
        text-align: center;
        padding: 0 20px;

        span {
            text-transform: uppercase;
            font-size: $font-size - 4px;
            line-height: $font-size;
            color: $footer-fg-color;
        }
    }
}

.download__app {
    max-height: $download-app-height;
    overflow: hidden;
    padding: 10px 0;
    background-color: $download-apps-bg;
    position: sticky;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    z-index: 9;

    .wrapper {

        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        max-width: 500px;
        margin: 0 auto;

        .text {
            justify-self: end;
            font-size: $font-size + 2px;
            color: $download-apps-text;
            font-weight: 500;
            text-transform: uppercase;
        }

        .app-links {
            justify-self: start;
            @include clearfix;
        }
    }

    .btn__app {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: $app-btn-bg;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        display: block;
        cursor: pointer;
        float: left;
        margin: 5px;
        @include easeOut;

        &.google {
            background-image: url('../images/google-store.png');
        }

        &.apple {
            background-image: url('../images/apple-store.png');
        }

        &.amazon {
            background-image: url('../images/amazon-store.png');
        }

        &:hover {
            background-color: darken($app-btn-bg, 10);
        }
    }

    @include bs-md {
        padding: 30px 0;
        max-height: unset;
        position: relative;

        .wrapper {
            grid-template-columns: 1fr;
            grid-row-gap: 10px;

            .text,
            .app-links {
                justify-self: center
            }
        }

    }
}
