.bg-blue {
    background-color: $blue !important
}

.bg-cyan {
    background-color: $cyan !important
}

.bg-saffron {
    background-color: $saffron !important
}

.text-blue {
    color: $blue !important
}

.text-cyan {
    color: $cyan !important
}

.text-saffron {
    color: $saffron !important
}

.text-white {
    color: white !important
}