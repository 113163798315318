.demo-modal {

    .cross {
        background: $cyan;
        color: #fff;
        font-size: $font-size;
        top: 0;
        padding: 0;
        right: 0;
        position: absolute;
        box-shadow: none;
        border: none;
        width: 30px;
        height: 30px;
        line-height: 1;
        font-weight: 400;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .modal-title {
        font-size: $font-size + 12px;
        font-weight: 500;
        padding: 25px 0 10px;
        text-align: center;
    }

    .modal-body {

        font-size: $font-size - 1;
        padding: 0 20px;

        .text {
            font-size: $font-size;
            color: $para-text-color;
            text-align: center;
            line-height: 1.2;
        }

        .logo {
            height: 100px;
            background: url('../images/LOGO-PatientMD.svg') no-repeat;
            background-size: 160px;
            background-position: center;
        }

        .form-wrapper {

            padding: 0 0 30px;

            input {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #e8e8e8;
                padding: 8px 0;
                @include easeOut;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-bottom-color: $blue;
                }
            }

            .action-button {
                text-align: center;
                padding: 35px 0 0;

                button {
                    padding: .6rem 2.5rem;
                    border-radius: 10rem;
                    font-size: $font-size;
                    color: white;
                    font-weight: 300;
                }
            }

        }
    }
}