header._pmd {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: z('site-header');
    background-color: $header-nav-bg-light;
    max-height: $header-max-height;

    &:not(.flat)::after {
        background-image: none;
        bottom: -5px;
        box-shadow: inset 0px 4px 8px -3px rgba(17, 17, 17, 0.06);
        content: '';
        height: 5px;
        left: 0px;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        right: 0px;
        width: 100%;
        z-index: z('site-header');
    }
}

.navbar-brand {
    img {
        height: 46px;
        margin-left: -6px;
        @include nofocusoutline;
    }

    &.mini {
        max-width: 42px;
        overflow: hidden;
        margin: 0;
    }

    @include nofocusoutline;
}

.navbar {

    .navbar-toggler {
        border: none;
        padding-left: 0;
        padding-right: 0;
        @include nofocusoutline;
    }

    @include bs-xl {
        padding-left: 0;
        padding-right: 0;

        .navbar-collapse {
            display: none !important;
        }
    }
}

.hamburger {
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    padding: 0;

    .line {
        width: 30px;
        height: 3px;
        background-color: #aaa;
        display: block;
        margin: 6px auto;
        border-radius: 2px;
        transition: all 0.4s ease-in-out;
    }

    &.active .line {
        &:nth-child(2) {
            opacity: 0;
        }

        &:nth-child(1) {
            transform: translateY(12px) rotate(45deg);
        }

        &:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
        }
    }

}

.nav-link.login-link {
    padding-right: 24px !important;
    position: relative;
    font-weight: 500;

    &::before {
        content: '';
        position: absolute;
        right: 1px;
        height: 20px;
        width: 20px;
        background: url('../images/register-btn-icon-blue.png') no-repeat;
        background-size: 13px;
        background-position: center;
        top: 50%;
        margin-top: -10px;
    }
}

.navbar-nav {
    li {
        display: inline-block;

        a:not(.dropdown-item) {
            transition: all .5s;
            position: relative;
            font-weight: 500;

            &:after,
            &:before {
                transition: all .5s;
            }

            &.active {
                color: $nav-active-color;
            }

            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 0%;
                content: '.';
                color: transparent;
                background: #aaa;
                height: 2px;
            }

            &.active:after {
                background: $nav-active-color;
                width: 100%;
            }

            &:hover:after {
                width: 100%;
            }
        }

        &:not(:last-child) {
            margin-right: .5rem;
        }
    }
}

.dropdown {
    .dropdown-menu {
        transition: all 0.3s, padding 1ms;

        border-radius: 0;

        max-height: 0;
        display: block;
        overflow: hidden;
        opacity: 0;

        @include bs-xl {
            border-width: 1px 0;
            margin-top: 0;
            padding: 0;

            .dropdown-item {
                padding: .65rem 1rem;
            }
        }
    }

    .mega-menu {
        margin-top: 0;
        padding: 0;
        height: 0;
        border-radius: 2px;
        width: 810px;
        margin-left: -236px;
        border: none;
        overflow: hidden;

        .nav-pills {
            border-bottom: 2px solid $blue;

            .nav-link {
                padding: 10px 10px 10px 30px;
                border-radius: 0;
                text-transform: uppercase;
                border-bottom: 3px solid transparent;
                font-size: 16px;
                text-align: left;
                position: relative;
                filter: grayscale(100%);
                max-width: 120px;

                &#patient-tab {
                    background: url('../images/dd-patient-tabicon.png') no-repeat;
                    background-size: 22px;
                    background-position-x: left;
                    background-position-y: center;
                }

                &#doctor-tab {
                    background: url('../images/dd-doctor-tabicon.png') no-repeat;
                    background-size: 18px;
                    background-position-x: left;
                    background-position-y: center;
                }

                &#business-tab {
                    background: url('../images/dd-biz-tabicon.png') no-repeat;
                    background-size: 18px;
                    background-position-x: left;
                    background-position-y: center;
                }

                &.active {
                    background: transparent;
                    border-bottom-color: $blue;
                    color: $blue;
                    filter: none;
                }

                &::after {
                    content: none
                }
            }

        }

        .tab-pane {
            padding: 5px 0;

            .dd-wrapper {

                display: grid;
                grid-template-columns: 1fr 1fr 1fr;

                .link-list {
                    ul {
                        padding: 8px 0 10px;
                        margin: 0;
                        list-style-type: none;

                        li {
                            display: block;

                            a {
                                color: $blue;
                                font-size: $font-size - 1px;
                                // padding: 8px 20px 8px 12px;
                                padding: 8px 24px 8px 0px;
                                display: block;
                                line-height: $font-size + 2px;
                                position: relative;

                                &:hover {
                                    color: $cyan;
                                    text-decoration: none
                                }

                                // &::before {
                                //     content: '\2022';
                                //     position: absolute;
                                //     left: 0;
                                //     top: 8px;
                                // }

                                &::after {
                                    content: none
                                }
                            }
                        }
                    }
                }

                .dd-info {
                    color: $blue;

                    .title {
                        font-size: ($font-size * 3);
                        font-weight: 500;
                        line-height: 1;
                        padding: 40px 0 40px 50px;

                        &.pat {
                            background-image: url('../images/dd-patient-tabicon.png');
                            background-repeat: no-repeat;
                            background-size: 40px;
                            background-position-y: center;
                        }

                        &.doc {
                            background-image: url('../images/dd-doctor-tabicon.png');
                            background-repeat: no-repeat;
                            background-size: 40px;
                            background-position-y: center;
                        }

                        &.biz {
                            background-image: url('../images/dd-biz-tabicon.png');
                            background-repeat: no-repeat;
                            background-size: 40px;
                            background-position-y: center;
                        }
                    }

                    .text {
                        font-size: $font-size;
                        line-height: $font-size + 6px;
                    }
                }
            }
        }

        @media (max-width:1200px) {
            display: none
        }
    }

    &:hover .mega-menu {
        max-height: 480px;
        height: auto;
        padding: 15px 25px;
        opacity: 1;
        box-shadow: 0 0 6px rgba(0, 0, 0, .05);
        overflow: auto;
        border: 1px solid #f2f2f2;
    }

    .login-menu {
        margin-top: 0;
        padding: 0;
        height: 0;
        border-radius: 0 0 2px 2px;
        width: 810px;
        border-color: #f2f2f2;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
        border: none;

        .as {
            padding: 1rem;
            border-radius: 2px;
            // padding-left: 100px;

            &.patient {
                background-color: $blue;
            }

            &.doctor {
                background-color: $cyan;
            }

            &.business {
                background-color: $saffron;
            }

            span {
                display: block;
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                color: #fff;
                font-weight: 400;
                font-size: $font-size;
            }

            ul {
                margin: 0;
                padding: 10px 0;
                list-style-type: none;

                li {
                    position: relative;
                    padding-left: 24px;
                    margin-bottom: 4px;

                    &::before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 16px;
                        width: 16px;
                        background: url('../images/register-btn-icon-white.png') no-repeat;
                        background-size: 13px;
                        background-position: center;
                        content: '';
                    }

                    a {
                        display: block;
                        line-height: $font-size;
                        font-size: $font-size - 2px;
                        padding: 0 0 4px;
                        font-weight: 300;
                        color: rgba(255, 255, 255, 1);

                        &:hover {

                            &::after {
                                content: none
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            display: none
        }
    }

    &:hover .login-menu {
        max-height: 400px;
        height: auto;
        padding: 15px 15px;
        opacity: 1;
        box-shadow: 0 0 6px rgba(0, 0, 0, .05);
        overflow: auto;
        border: 1px solid #f2f2f2;
    }

}

.sidebar {

    @include bs-xl {
        position: fixed;
        background: #fff;
        top: $header-max-height;
        right: 0px;
        transition: all 0.3s ease 0s;
        transform: translateX(100%);
        width: 280px;
        height: 100vh;
        z-index: z("side-drawer");
        border-top: 1px solid #eee;
        box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.05);

        .navbar-nav li {
            margin: 0;

            .nav-link {
                padding: .75rem 1.5rem;

                &::before,
                &::after {
                    content: none
                }

                &:hover {
                    background-color: $nav-hover-color;
                }
            }
        }
    }

    &.collapsing {
        height: 100vh !important;
        transition: all 0.3s ease;
    }

    &.show {
        transform: translateX(0);
    }
}