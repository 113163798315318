.modal.side-drawer .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.side-drawer .modal-content {
    height: 100%;
    overflow-y: auto;
    border: none;
    border-radius: 0;
}

.modal.side-drawer .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.side-drawer.in .modal-dialog {
    right: 0;
}

.modal.side-drawer {
    .modal-body {

        .hamburger {
            position: absolute;
            top: 20px;
            right: 19px;

            .line {
                height: 2px;
                width: 24px;
                margin: 6px auto;
            }

            &.active {
                .line:first-child {
                    transform: translateY(10px) rotate(45deg);
                }
            }
        }

        padding: $header-max-height 0 0;
        background-color: $header-nav-bg-light;
    }

    .drawer-list {

        margin-bottom: 0;

        &:not(.collapse):not(.collapsing) {
            border-top: 1px solid $divider-color;
        }

        & li {
            a {
                text-decoration: none;
                display: block;
                padding: 16px 10px 16px 20px;
                cursor: pointer;
                color: #454545;
                line-height: 20px;
                font-weight: 400;

                &:not(.accordion-heading) {
                    padding: 16px 20px;
                }
            }

            &+li a {
                border-top: 1px solid $divider-color;
            }
        }

        &>li>a {}

        &>li>a:hover {
            background-color: darken($nav-hover-color, 10);
        }

        .accordion-heading {
            position: relative;

            &:not(.collapsed)::after {
                content: '\25be';
                position: absolute;
                height: 20px;
                width: 12px;
                right: 10px;
                top: 50%;
                margin-top: -10px;
                color: #888;
                font-size: 12px;
                text-align: center;
                transform: rotate(180deg);
            }

            &.collapsed::after {
                content: '\25be';
                position: absolute;
                height: 20px;
                width: 12px;
                right: 10px;
                top: 50%;
                margin-top: -10px;
                color: #888;
                font-size: 12px;
                text-align: center;
            }

        }

        &.menu-level-1 li a {
            background-color: darken($header-nav-bg-light, 4);

            // &::before {
            //     content: '';
            //     background: #aaa;
            //     position: absolute;
            //     left: 0;
            //     top: -1px;
            //     width: 3px;
            //     bottom: -1px
            // }
        }

        &.menu-level-2 li a {
            background-color: darken($header-nav-bg-light, 6);

            // &::before {
            //     content: '';
            //     background: #aaa;
            //     position: absolute;
            //     left: 0;
            //     top: -1px;
            //     width: 3px;
            //     bottom: -1px
            // }
        }
    }
}