@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
$font-family: 'Roboto',
sans-serif;

$btn-border-radius:3px;

$blue: #0072c9;
$blue-alt: #2078bc;
$cyan: #07b4b4;
$saffron: #ff7400;

$section-heading-color: #323232;
$para-text-color: #676767;
$heading-color: #323232;
$divider-color: #e8e8e8;

$font-size: 16px;
$header-nav-bg-light: #fff;
$header-max-height: 72px;

$nav-active-color: #44aee0;
$nav-hover-color: #f2f2f2;

$footer-col-header-color: #e9e9e9;
$footer-link-color: #9cacb7;
$footer-bg-color: #3b4750;
$footer-fg-color: #9cacb7;

$download-apps-bg: #e6f5ff;
$download-apps-text: #2a4153;
$app-btn-bg: #2078bc;
$download-app-height: 70px;