section.mini-header {
    display: none;

    @include mq-lg {

        $text-color: #888;
        border-bottom: 1px solid #f2f2f2;
        display: block;

        .contact-strip,
        .social-strip {
            padding: 0;

            ul {
                list-style-type: none;
                margin: 8px 0;

                li {
                    display: inline-block;
                    font-size: $font-size - 1px;
                    line-height: 1;
                    color: #888;
                    font-weight: 300;
                }
            }

            @include mq-xl {
                padding: 0 20px;
            }
        }

        .contact-strip {
            text-align: left;

            ul {
                li {

                    span i.fa {
                        color: $blue;
                        margin: 0 4px;
                    }

                    &:not(:first-child):not(:last-child) {
                        position: relative;
                        padding-right: 15px;
                        margin-right: 12px;

                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            width: 1px;
                            background-color: #ddd
                        }
                    }

                    a {
                        color: $text-color;
                        font-weight: 400;
                        font-size: $font-size - 2px;
                        @include easeOut;

                        &:hover {
                            color: $blue;
                        }
                    }
                }
            }
        }

        .social-strip {
            text-align: right;

            ul {
                li {
                    &:not(:last-child) {
                        margin-right: 15px;
                    }

                    a {
                        font-size: $font-size - 1px;
                        color: $text-color;
                        @include easeOut;

                        &:hover {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
}

section.hero {
    min-height: 80vh;

    .hero__content {

        display: grid;
        align-items: center;

        .text {
            h1 {
                font-weight: 600;
                font-size: $font-size * 2.5;
            }

            p {
                font-size: $font-size;
            }
        }

        .action-buttons {

            a.btn,
            button.btn {
                padding: 18px 30px;
                color: #fff;
                border-radius: $btn-border-radius;
                margin-bottom: 8px;
                min-width: 180px;

                &.register-btn,
                &.demo-btn {
                    position: relative;
                    line-height: 18px;

                    span.arr {
                        content: '';
                        position: absolute;
                        right: 16px;
                        top: 50%;
                        margin-top: -11px;
                        height: 20px;
                        width: 20px;
                    }
                }

                &.register-btn {

                    padding-right: 50px;

                    span.arr {
                        background: url('../images/register-btn-icon-white.png') no-repeat;
                        background-size: contain;
                    }
                }

                &.demo-btn {
                    padding-right: 40px;

                    span.arr {
                        margin-top: -10px;
                        right: 18px;
                        background: url('../images/btn-arrow-white.png') no-repeat;
                        background-size: contain;
                        background-size: 12px;
                        background-position: center;
                    }

                    &.has-effect:hover {
                        span {
                            animation: btn-slide 1s ease-in-out infinite;
                        }
                    }
                }

                @include mq-sm {
                    min-width: 160px;
                }
            }
        }
    }
}

section.feature {

    background-color: #fff;
    // outline: 1px solid #eee;

    .feature-heading {
        text-align: center;
        font-size: ($font-size * 2) + 6px;
        line-height: 1.2;
        padding: 0 20px 4rem;
        font-weight: 500;

        &.sm {
            font-size: $font-size * 2;
        }

        &.has-sub {
            .sub {
                padding: 30px 20px 20px;
                font-size: $font-size + 2px;
                font-weight: 400;
                line-height: $font-size + 8px;
                color: $para-text-color;
            }
        }
    }

    .feature__content {
        @include mq-lg {
            &.centered {
                display: grid;
                height: 100%;
                align-items: center;
            }
        }
    }

    .text {
        min-height: 300px;

        .feat-heading {
            display: grid;
            grid-template-columns: 1fr;
            justify-items: center;
            grid-gap: 15px;
            position: relative;

            @include mq-sm {
                &.resp-sm {
                    grid-template-columns: 90px 1fr;
                    justify-items: start;
                }
            }

            @include mq-md {
                &.resp-md {
                    grid-template-columns: 90px 1fr;
                    justify-items: start;
                }
            }

            @include mq-lg {
                &.resp-lg {
                    grid-template-columns: 90px 1fr;
                    justify-items: start;
                }
            }

            .icon {
                background: #2078bc;
                height: 90px;
                width: 90px;
                border-radius: 50%;
            }

            .title {
                font-size: $font-size * 2;
                line-height: 1.1;
                font-weight: 600;
                display: grid;
                align-items: center;
                color: $heading-color;
                text-align: center;
                padding: 0 30px;

                @include mq-md {
                    text-align: left;
                    padding: 0
                }
            }
        }

        .sub-text {
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: .5rem;
            font-size: $font-size - 2px;
            color: $blue;
            font-weight: 500;
            text-align: center;

            @include mq-lg {
                text-align: left;
                padding-bottom: 1rem;
            }
        }

        .big-text {
            color: $section-heading-color;
            font-size: $font-size * 2.5;
            font-weight: 600;
            line-height: 1.2;
            text-align: center;
            padding: 0 1rem;

            @include mq-lg {
                text-align: left;
                padding: 0;
            }
        }

        .para-text {
            font-weight: 400;
            margin: 20px 0;
            color: $para-text-color;
            font-size: $font-size + 2px;
            line-height: $font-size + 8px;
            text-align: justify;
            padding: 0 1.5rem;

            @include mq-lg {
                text-align: left;
                padding: 0;
            }
        }

        @include mq-lg {
            padding: 0 2rem;
            min-height: unset
        }
    }

    ul.key-points {
        list-style-type: none;
        padding: 0;
        margin: 10px 0;

        li {
            position: relative;
            padding-left: 15px;
            margin: 5px 0;
            text-align: left;

            &::before {
                content: '\2022';
                position: absolute;
                left: 0;
                top: 1px;
            }
        }
    }

    .action-buttons {
        text-align: center;

        a.btn,
        button.btn {
            padding: .75rem 1.825rem;
            font-weight: 300;
            font-size: $font-size;
            line-height: $font-size + 4px;
            border-radius: $btn-border-radius;
            margin: 10px 0;

            &:not(.bg-white) {
                color: #fff
            }

            &:not(:disabled):hover {
                background: darken($blue, 10) !important
            }

            &.bg-white:hover {
                background: #f2f2f2 !important
            }
        }

        .btn+.btn {
            margin-left: 10px;
        }

        @include mq-lg {
            text-align: left
        }
    }

    &.spacious {
        margin: 30px 0;
    }

}

.youtube-play-btn {
    background: #ff0000;
    border-radius: 50% / 10%;
    color: #FFFFFF;
    font-size: 1.5em;
    height: 2.5em;
    padding: 0;
    position: absolute;
    text-align: center;
    text-indent: 0.1em;
    transition: all 150ms ease-out;
    width: 3em;
    left: 50%;
    top: 50%;
    margin-left: -1.5em;
    margin-top: -1.25em;
    opacity: 0.8;
    cursor: pointer;

    &::before {
        background: inherit;
        border-radius: 5% / 50%;
        bottom: 9%;
        content: "";
        left: -5%;
        position: absolute;
        right: -5%;
        top: 9%;
    }

    &::after {
        border-style: solid;
        border-width: 1em 0 1em 1.732em;
        border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
        content: ' ';
        font-size: 0.5em;
        height: 0;
        margin: -1em 0 0 -0.75em;
        top: 50%;
        position: absolute;
        width: 0;
    }
}
